<!-- 操作履歴の詳細情報を表示するためのダイアログ -->
<template>
  <div>
    <v-dialog
      v-model="dialogModel"
      min-width="640px"
      :max-width="'90%'"
      @input="closeDialog"
    >
      <v-card>
        <v-card-title class="headline primary">
          操作履歴の詳細表示
        </v-card-title>
        <v-card-text>
          <v-container>

            <v-row dense align="center">
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="item.timestamp"
                  label="操作日時"
                  readonly
                />
              </v-col>
            </v-row>

            <v-row dense align="center">
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="item.email"
                  label="操作者のEmailアドレス"
                  readonly
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="item.staff_name"
                  label="操作者の氏名"
                  readonly
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="item.org_name"
                  label="操作者の所属組織"
                  readonly
                />
              </v-col>
            </v-row>

            <v-row dense align="center">
              <v-col cols="12" md="12">
                <v-textarea
                  v-model="item.message"
                  label="操作内容"
                  auto-grow
                  rows=1
                  readonly
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row dense align="center" v-if="isManagementAdmin">
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="item.tags"
                  label="タグ"
                  readonly
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="item.host_ip"
                  label="接続元IPアドレス"
                  readonly
                />
              </v-col>
            </v-row>

            <v-row dense align="center" v-if="isManagementAdmin">
              <v-col cols="12" md="12">
                <v-textarea
                  v-model="item.user_agent"
                  label="ユーザーエージェント"
                  auto-grow
                  rows=1
                  readonly
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row dense align="center" v-if="isManagementAdmin">
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="item.staff_id"
                  label="操作者ID"
                  readonly
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="item.org_id"
                  label="操作者の所属組織ID"
                  readonly
                />
              </v-col>
              <v-col cols="12" md="12">
                <v-textarea
                  :value="operationInfo"
                  label="その他の情報"
                  auto-grow
                  rows=1
                  readonly
                ></v-textarea>
              </v-col>
            </v-row>

          </v-container>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="primary darken-1" text @click="closeDialog">閉じる</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: 'OperationHistoryDialog',
  props: {
    value: Boolean,
    item: Object,
    mode: {
      type: String,
      default: 'none'
    },
  },
  components: {
  },
  mixins: [
  ],
  data() {
    return {
      dialogModel: false,
    }
  },
  computed: {
    operationInfo() {
      return JSON.stringify(this.item.item);
    },
    isManagementAdmin() {
      if (this.$store.state.user.role.endsWith('management-admin')) return true;
      return false;
    }
  },
  methods: {
    closeDialog() {
      this.dialogModel = false;
      this.$emit('closed');
    },
  },
  mounted() {
    console.log('Mounted OperationHistoryDialog');
    this.dialogModel = this.value;
  },
  watch: {
    value(value) {
      this.dialogModel = value;
    },
  },
}
</script>

<style scoped>
  .primary {
    color: white;
  }
</style>