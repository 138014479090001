<template>
  <div class="pa-4">
    <div v-if="$store.state.user.role.startsWith('customer')" class="ml-4 text-caption"><a @click="$router.back()">戻る</a></div>
    <v-container fluid>
      <h2 text color="black" class="headline">スタッフの操作履歴</h2>
      <p class="text-body-1 mt-2">表示する日時を指定してください。</p>
      <v-row dense>
        <v-col cols="12">

          <v-card class="v-card--flat">
            <v-toolbar
              color="primary"
              flat
              dark
              height="auto"
              min-width="220px"
              class="top-rounded"
              :collapse="isCollapse"
            >

              <div class="align-self-start d-flex pt-2 toolbar-default-space">

                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      class="align-center"
                      v-model="dateTextField"
                      label="表示日（年/月/日）"
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                      @input="date = toCalDate(dateTextField)"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    locale="jp-ja"
                    :day-format="date => new Date(date).getDate()"
                    no-title
                    scrollable
                    @input="dateTextField = toTextDate(date)"
                  />
                </v-menu>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      @click="toggleCollapse()"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon class="mx-3" v-if="!isCollapse">mdi-toggle-switch-outline</v-icon>
                      <v-icon class="mx-3" v-else>mdi-toggle-switch-off-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>検索欄の表示/非表示</span>
                </v-tooltip>
              </div>

              <v-row class="align-center pb-3 pt-4" v-if="!isCollapse">

                <v-col cols="12" sm="3">
                  <v-text-field
                    dense
                    type="time"
                    step=1
                    v-model="searchTimeStart"
                    clearable
                    hide-details
                    label="表示時間（開始）"
                    @blur="subscribeItems"
                    @keyup.enter="subscribeItems"
                  />
                </v-col>
                &nbsp;〜&nbsp;
                <v-col cols="12" sm="3">
                  <v-text-field
                    dense
                    type="time"
                    step=1
                    v-model="searchTimeEnd"
                    clearable
                    hide-details
                    label="表示時間（終了）"
                    @blur="subscribeItems"
                    @keyup.enter="subscribeItems"
                  />
                </v-col>

                <v-col cols="12" sm="5">
                </v-col>

                <v-col cols="12" sm="4" v-if="isManagementRole && isAdminRole">
                  <v-select
                    dense
                    hide-details
                    v-model="searchSelectedOrgType"
                    :items="selectOrgTypeList"
                    label="組織種別"
                    no-data-text="選択対象がありません。"
                    @change="changeSearchSelectedOrgType"
                  />
                </v-col>

                <v-col cols="12" sm="4" v-if="isManagementRole && isAdminRole">
                  <v-select
                    dense
                    hide-details
                    v-model="searchSelectedOrg"
                    label="組織"
                    :items="searchOrgItems"
                    :disabled="!searchSelectedOrgType || searchSelectedOrgType == 'management'"
                    @change="changeSearchSelectedOrg"
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="4" v-if="isManagementRole && isAdminRole">
                  <v-checkbox
                    dense
                    hide-details
                    v-model="isDeletedOrg"
                    label="削除された組織を選択する"
                    @change="changeIsDeletedOrg"
                    :disabled="!searchSelectedOrgType || searchSelectedOrgType == 'management'"
                  />
                </v-col>

                <v-col cols="12" sm="4" v-if="isAdminRole">
                  <v-select
                    dense
                    hide-details
                    v-model="searchSelectedStaff"
                    label="スタッフ"
                    :items="searchStaffItems"
                    @change="subscribeItems"
                    :disabled="(
                        (isManagementRole && searchSelectedOrg == '' && searchSelectedOrgType != 'management')
                      )"
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="5" v-if="isManagementRole && isAdminRole">
                  <v-checkbox
                    dense
                    hide-details
                    v-model="isDeletedStff"
                    label="削除されたスタッフを選択する"
                    @change="changeIsDeletedStff"
                    :disabled="(searchSelectedOrg == '' && searchSelectedOrgType != 'management') || isDeletedOrg "
                  />
                </v-col>

                <v-col cols="12" sm="5">
                  <v-select
                    dense
                    hide-details
                    v-model="searchSelectedOperationType"
                    :items="selectOperationTypeList"
                    label="操作種別"
                    no-data-text="選択対象がありません。"
                    @input="subscribeItems"
                  />
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    dense
                    label="メッセージ（部分一致検索）"
                    clearable
                    v-model="searchKeyword"
                    prepend-inner-icon="search"
                    @blur="subscribeItems"
                    @keyup.enter="subscribeItems"
                    hide-details
                    outlined
                  />
                </v-col>

              </v-row>    
            </v-toolbar>
          </v-card>

        </v-col>
      </v-row>  

      <v-row dense v-if="isOverQueryLimit">
        <v-col cols="12">
          <v-alert
            class="ma-0"
            dense
            dismissible
            outlined
            v-model="isOverQueryLimit"
            type="warning"
            prominent
          >
            <span v-for="(msg, index) in msgsOverQueryLimit" :key="index">
            {{ msg }}<br />
            </span>
          </v-alert>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">

          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page.sync=selectedLinesPerPage
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            @update:sort-by="updateSortBy"
            @update:sort-desc="updateSortDesc"
            hide-default-footer
            fixed-header
            height="700"
          >
            <template v-slot:top="{ pagination, options, updateOptions }">
              <v-data-footer
                :pagination="pagination"
                :options="options"
                @update:options="updateOptions"
                items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                :items-per-page-options="linesPerPageList"
                class="border-less"
              />
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="viewItemForm(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon class="mx-3">remove_red_eye</v-icon>
                  </v-btn>
                </template>
                <span>詳細表示</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <device-status-chip :item="item" />
            </template>

            <template v-slot:[`item.center_id`]="{ item }">
              {{centers[item.center_id] ? centers[item.center_id].name: ''}}
            </template>
            <template v-slot:[`item.order_org_id`]="{ item }">
              {{customerOrgs[item.order_org_id] ? customerOrgs[item.order_org_id].name: ''}}
            </template>

          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <operation-history-dialog
      v-model="operationHistoryDialogModel"
      :item="currentItem"
      @closed="onDialogClosed"
    />

  </div>
</template>

<script>
import dateformat from 'dateformat';
import OperationHistoryDialog from '@/components/OperationHistoryDialog.vue';

let unsubscribeItems = null;

export default {
  name: 'OperationHistory',
  components: {
    OperationHistoryDialog,
  },
  mixins:[
  ],
  data() {
    return {
      date: dateformat(new Date(), 'yyyy-mm-dd'),
      dateTextField: dateformat(new Date(), 'yyyy/mm/dd'),
      menu: false,
      headers: [
        {
          text: '操作',
          align: 'left',
          sortable: false,
          value: 'action',
          width: '70px',
        },
        {
          text: '操作日時',
          align: 'left',
          sortable: true,
          value: 'timestamp',
          width: '200px',
        },
        {
          text: '名前',
          align: 'left',
          sortable: true,
          value: 'staff_name',
          width: '100px',
        },
        {
          text: '組織種別',
          align: 'left',
          sortable: true,
          value: 'org_type_name',
          width: '110px',
        },
        {
          text: '組織名',
          align: 'left',
          sortable: true,
          value: 'org_name',
          width: '150px',
        },
        {
          text: '操作種別',
          align: 'left',
          sortable: true,
          value: 'operation_type',
          width: '150px',
        },
        {
          text: 'メッセージ',
          align: 'left',
          sortable: true,
          value: 'message',
          width: '250px',
        },
      ],
      items: [],
      searchKeyword: '',
      accessTypeModel: 'all',
      searchSelectedField: 'デバイスID',
      searchFields: ['デバイスID'],
      centers: {},
      customerOrgs: {},
      searchSelectedOrgType: null,
      searchSelectedOperationType: null,
      selectDeviceTypeList: [
        { text: '全て', value: null },
        { text: 'ホルター心電計', value: 'holter' },
        { text: '通信用ドングル／ソフト', value: 'dongle'},
      ],
      searchCustomerKeyword: '',
      isCollapse: true,
      searchTimeStart: '00:00:00',
      searchTimeEnd: '23:59:59',
      selectedLinesPerPage: 10,
      linesPerPageList: [10, 30, 50],
      currentItem: {},
      operationHistoryDialogModel: false,
      searchSelectedOrg: '',
      searchSelectedStaff: '',
      isDeletedOrg: false,
      isDeletedStff: false,
      searchOrgItems: [{ text: '全て', value: '' }],
      searchStaffItems: [{ text: '全て', value: '' }],
      isOverQueryLimit: false,
      sortBy: ['timestamp'],
      sortDesc: [true],
    };
  },
  computed: {
    selectOrgTypeList() {
      let list = [{ text: '全て', value: null }];
      for (let key in this.$store.state.orgTypes) {
        list.push({
          text: this.$store.state.orgTypes[key],
          value: key,
        });
      }
      return list;
    },
    selectOperationTypeList() {
      let list = [{ text: '全て', value: null }];
      list = list.concat(this.getOperationTypeList());
      return list;
    },
    isReceptionRole() {
      if (this.$store.state.user.role.startsWith('reception')) return true;
      return false;
    },
    isCustomerRole() {
      if (this.$store.state.user.role.startsWith('customer')) return true;
      return false;
    },
    isCenterRole() {
      if (this.$store.state.user.role.startsWith('center')) return true;
      return false;
    },
    isManagementRole() {
      if (this.$store.state.user.role.startsWith('management')) return true;
      return false;
    },
    isAdminRole() {
      if (this.$store.state.user.role.endsWith('-admin')) return true;
      return false;
    },
  },
  methods: {
    viewItemForm(item) {
      this.currentItem = item;
      this.operationHistoryDialogModel = true;
    },
    onDialogClosed() {
      this.operationHistoryDialogModel = false;
      this.currentItem = {};
    },
    timestampToString(timestamp, format='yyyy/mm/dd HH:MM'){
      try {
        return timestamp ? dateformat(new Date(timestamp.seconds * 1000), format): null;        
      } catch (e) {
        return null;
      }
    },
    subscribeItems() {
      // 購読中は一旦解除した上で対応する。
      if (unsubscribeItems) unsubscribeItems();

      let query = this.$db.collectionGroup('operation_history_data');

      // 運営主体スタッフ以外は所属組織のデータのみ
      if (!this.$store.state.user.role.startsWith('management')) {
        query = query.where('org_id', '==', this.$store.state.user.org_id);
        if (!this.$store.state.user.role.endsWith('-admin')) {
          // 管理者でない場合は自分のデータのみ
          query = query.where('email', '==', this.$store.state.user.email);
        }
      } else if (this.$store.state.user.role === 'management') {
          query = query.where('email', '==', this.$store.state.user.email);
      }

      // 時間でフィルタ(日時は必須とする)
      if (this.searchTimeStart == null || this.searchTimeStart.trim() == '')  this.searchTimeStart = '00:00:00';
      if (this.searchTimeEnd == null || this.searchTimeEnd.trim() == '')  this.searchTimeEnd = '23:59:59';
      const startDateTime = new Date(this.dateTextField + ' ' + this.searchTimeStart.trim());
      if (startDateTime.toString() === 'Invalid Date') return;
      const endDateTime = new Date(this.dateTextField + ' ' + this.searchTimeEnd.trim());
      if (endDateTime.toString() === 'Invalid Date') return;
      // 開始日時
      query = query.where("timestamp", ">=", startDateTime);
      // 終了日時
      endDateTime.setSeconds(endDateTime.getSeconds() + 1); // 1秒加算し未満ではなく、”よりちいさい”にする
      query = query.where("timestamp", "<", endDateTime);

      if (this.searchSelectedOrgType != null) {
        // 組織種別でフィルタ
        query = query.where('org_type', '==', this.searchSelectedOrgType);
        // 組織でフィルタ
        if (this.searchSelectedOrg != '') query = query.where('org_id', '==', this.searchSelectedOrg);
        // スタッフでフィルタ
        if (this.searchSelectedStaff != '') query = query.where('staff_id', '==', this.searchSelectedStaff);
      }

      // 操作種別でフィルタ
      if (this.searchSelectedOperationType != null) query = query.where('tags', 'array-contains', this.searchSelectedOperationType);

      // 新しいものを優先に表示
      query = query.orderBy('timestamp', 'desc');

      // 上限（上限に達したことを判定するために＋１件する）
      const queryLimit = this.$store.state.configs.query_limit;
      this.msgsOverQueryLimit = [
        `表示対象データが${queryLimit}件を超えました。${queryLimit + 1}件以上は表示されません。${queryLimit}件以内にするためには各種検索条件を調整してください。  ※ 条件「メッセージ（部分一致検索）」では調整できません。`
      ];
      query = query.limit(queryLimit + 1);

      unsubscribeItems = query.onSnapshot((querySnapshot) => {
        // 最初および変更時の読み取り
        let items = [];
        let listCount = 0;
        this.isOverQueryLimit = false;

        // クエリ上限を超えた場合
        if (querySnapshot.docs.length > queryLimit) this.isOverQueryLimit = true;

        querySnapshot.forEach((doc) => {

          // クエリ件数が上限を超えた場合は終了
          listCount = listCount + 1;
          if (listCount > queryLimit) return;

          let data = doc.data();

          // メッセージでlike検索
          if (this.searchKeyword && this.searchKeyword.length > 0) {
            const reg = new RegExp(`.*${this.searchKeyword}.*`);
            if (!reg.test(data.message)) return;
          }

          data.timestamp = this.timestampToString(data.timestamp, 'yyyy/mm/dd HH:MM:ss');
          data.org_type_name = this.$store.state.orgTypes[data.org_type] ? this.$store.state.orgTypes[data.org_type] : '';
          data.operation_type = this.getOperationTypeName(data.tags);
          data.id = doc.id;
          items.push(data);   
        });
        this.items = items;
      }, (e) => {
        console.log('error', e);
        this.items = [];
      });
    },
    toggleCollapse() {
      this.isCollapse = !this.isCollapse;
    },
    getOperationTypeName(tags, types = this.$store.state.logOperationTypes) {
      for (let key in types) {
        if (tags.includes(key)) return types[key];
      }
      return '';
    },
    getOperationTypeList() {
      // ログインユーザー毎にリストを変える
      let types = this.$store.state.logOperationTypes
      if (this.$store.state.user.role.startsWith('reception')) {
        types = this.$store.state.logOperationTypesForReception;
      } else if (this.$store.state.user.role.startsWith('customer')) {
        types = this.$store.state.logOperationTypesForCustomer;
      }
      // リストの作成
      let list = [];
      for (let key in types) {
        list.push({ text: types[key], value: key });
      }
      return list;
    },
    toCalDate(date) {
      if (!date) return null;
      date = new Date(date);
      if (date.toString() === "Invalid Date") return null;
      return dateformat(date, 'yyyy-mm-dd');
    },
    toTextDate(date) {
      if (!date) return null;
      date = new Date(date);
      if (date.toString() === "Invalid Date") return null;
      return dateformat(date, 'yyyy/mm/dd');
    },
    isSwitchingUser() {
      // superUserが存在しない場合は、なりすまし不可
      if (!this.$store.state.superUser) {
        return false;
      }
      // ログイン中のユーザーIDと、superUserのIDが同一の場合はなりすましではない
      if (this.$store.state.user.id == this.$store.state.superUser.id) {
        return false;
      }
      return true;
    },
    getOrgItems(orgs) {
      let items = [{text: '全て', value: ''}];
      for (const id in orgs) {
        const org = orgs[id];
        items.push({
          text: org.name,
          value: org.id
        });
      }
      return items;
    },
    async getDeletedOrgItems(type) {
      let items = [{text: '全て', value: ''}];

      let collectionName = 'staff_orgs';
      if (type == 'customer') {
        collectionName = 'customer_orgs';
      } else if (type == 'management') {
        return items;
      }

      let snapshot = await this.$db.collection(collectionName)
        .where('type', '==', type)
        .get();

      if (!snapshot.empty) {
        for (const doc of snapshot.docs) {
          if (!doc.exists) continue;
          const data = doc.data();
          if (!('deleted' in data) || !data.deleted) continue;
          items.push({
            text: data.name,
            value: doc.id
          });
        }
      }

      return items;
    },
    async getSearchOrgItems(isDeletedOrg, searchSelectedOrgType) {
      let items = [{text: '全て', value: ''}];
      if (isDeletedOrg) {
        items = await this.getDeletedOrgItems(searchSelectedOrgType);
      } else {
        switch (searchSelectedOrgType) {
          case 'center':
            items = this.getOrgItems(this.$store.state.centersDict);
            break;
          case 'reception':
            items = this.getOrgItems(this.$store.state.receptionsDict);    
            break;
          case 'customer':
            items = this.getOrgItems(this.$store.state.customerOrgsDict); 
            break;
        }
      }
      return items;
    },
    async getSearchStaffItems(isDeletedStff, searchSelectedOrg, searchSelectedOrgType) {
      let items = [{text: '全て', value: ''}];

      const collectionName = searchSelectedOrgType == 'customer' ? 'customer_staffs' : 'staffs';
      let query = this.$db.collection(collectionName)
        .where('type', '==', searchSelectedOrgType);

      // 組織で運指主体以外が指定されている場合、組織IDを指定
      if (searchSelectedOrgType != 'management') query = query.where('org_id', '==', searchSelectedOrg);

      // 削除済みスタッフが無効の場合は、削除されていないスタッフを抽出
      if (!isDeletedStff) query = query.where('deleted', '==', null);

      const snapshot = await query.get();

      if (!snapshot.empty) {
        for (const doc of snapshot.docs) {
          if (!doc.exists) continue;
          const data = doc.data();

           // 削除済みスタッフが有効の場合は、削除されていないスタッフをスキップ
          if (
            isDeletedStff
            && (!('deleted' in data) || !data.deleted)
          ) continue;

          items.push({
            text: data.name,
            value: doc.id
          });
        }
      }

      return items;
    },
    changeIsDeletedOrg() {
      const value = this.isDeletedOrg;
      this.getSearchOrgItems(value, this.searchSelectedOrgType)
        .then((items) => {
          this.searchOrgItems = items;
        });
      this.searchSelectedOrg = '';
      this.searchSelectedStaff = '';
      this.isDeletedStff = value;
      this.subscribeItems();
    },
    changeSearchSelectedOrgType() {
      const value = this.searchSelectedOrgType;
      this.searchSelectedStaff = '';
      this.getSearchOrgItems(this.isDeletedOrg, value)
        .then((items) => {
          this.searchOrgItems = items;
        });
      if (value == 'management') {
        // 運営主体の場合は、スタッフも設定する
        this.getSearchStaffItems(this.isDeletedStff, this.searchSelectedOrg, value)
          .then((items) => {
            this.searchStaffItems = items;
          });
      }
      this.isDeletedOrg = false;
      this.isDeletedStff = false;
      this.searchSelectedOrg = '';
      this.searchSelectedStaff = '';
      this.subscribeItems();
    },
    changeIsDeletedStff() {
      const value = this.isDeletedStff;
      this.searchSelectedStaff = '';
      this.getSearchStaffItems(value, this.searchSelectedOrg, this.searchSelectedOrgType)
        .then((items) => {
          this.searchStaffItems = items;
        });
      this.subscribeItems();
    },
    changeSearchSelectedOrg() {
      const value = this.searchSelectedOrg;
      this.searchSelectedStaff = '';
      this.getSearchStaffItems(this.isDeletedStff, value, this.searchSelectedOrgType)
        .then((items) => {
          this.searchStaffItems = items;
        });
      this.subscribeItems();
    },
    updateSortBy(v) {
      this.sortBy = v;
    },
    updateSortDesc(v) {
      this.sortDesc = v;
    },
  },
  watch: {
    dateTextField(date) {
      if (!date) return;
      date = new Date(date);
      if (date.toString() === "Invalid Date") return;
      this.subscribeItems();
    },
    selectedLinesPerPage(lineNumber) {
      if (this.$store.state.user.table_lines_par_page != lineNumber) {
        const collectionName = this.$store.state.user.role.startsWith('customer') ? 'customer_staffs' : 'staffs';
        const staffsRef = this.$db
          .collection(collectionName)
          .doc(this.$store.state.user.id);
        // 運営主体管理ユーザーによる、なりすましの場合は更新しない
        if (!this.isSwitchingUser()) {
          staffsRef.update({
            table_lines_par_page: lineNumber
          });
        }
        this.$store.commit('setUserTableLinesParPage', lineNumber);
      }
    },
  },
  mounted() {
    // テーブル表示件数の初期読み込み
    if (this.$store.state.user.table_lines_par_page) {
      this.selectedLinesPerPage = this.$store.state.user.table_lines_par_page;
    }
    // 他の画面で表示件数が変更された場合、こちらの画面にも反映させる
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'setUserTableLinesParPage') {
        this.selectedLinesPerPage = state.user.table_lines_par_page;
      }
    });

    if (!this.isManagementRole) {
      this.searchSelectedOrgType = this.$store.state.user.type;
      this.searchSelectedOrg = this.$store.state.user.org_id;
      this.getSearchStaffItems(false, this.searchSelectedOrg, this.searchSelectedOrgType)
        .then((items) => {
          this.searchStaffItems = items;
        });
    }

    this.subscribeItems();
  },
  beforeDestroy() {
    // 変更監視を停止
    if (unsubscribeItems) unsubscribeItems();
  },
}
</script>

<style>
  .top-rounded {
    margin:0px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .toolbar-default-space {
    width: 200px;
    min-width: 200px;
    margin-right: 12px;
  }

  input[type="time"]::-webkit-calendar-picker-indicator {
    background-color: white;
    border-radius: 5px;
    margin: 0;
  }
  
  .border-less {
    border: none !important;
  }

  div.v-card--flat.v-card.v-sheet.theme--light > header {
    max-height: 233px !important;
  }

</style>